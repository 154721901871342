<template>
    <v-container grid-list-xl v-if="!$store.getters.isLoading" fluid class="mb-16">
        <h1>Sessões ativas</h1>
        <v-divider />
        <BoardsError />

        <v-row dense v-if="loading">
            <v-progress-circular class="mt-12" size="60" width="12" color="primary" indeterminate />
        </v-row>
        <div v-else>
            <v-row dense>
                <v-col cols="6" v-if="itensByPay.length > 0">
                    <v-switch v-model="onlyPay" label="Somente com pagamento" dense />
                </v-col>
                <v-col cols="6" v-if="itensOffline.length > 0">
                    <v-switch v-model="onlyOnline" label="Somente online" dense />
                </v-col>
            </v-row>
            <v-row dense>
                <v-col :cols="itensByPay.length > 0 ? 6 : 12">
                    <v-card color="primary">
                        <v-card-text class="white--text">
                            <div class="headline mb-2">
                                <v-icon dark>score</v-icon>
                                {{ items.length }}
                            </div>
                            Sessões ativas
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="6" v-if="itensByPay.length > 0">
                    <v-card color="success">
                        <v-card-text class="white--text">
                            <div class="headline mb-2">
                                <v-icon dark>paid</v-icon>
                                {{ itensByPay.length }}
                            </div>
                            Por pagamento
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-list subheader>
                <v-list-item v-for="(item, i) in listWithFilter" :key="i" class="elevation-2" color="primary">
                    <v-list-item-avatar>
                        <v-icon :class="classColor(item)"> {{ icon(item) }} </v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title>
                            Carregador: <strong>{{ item.charger.name }}</strong>
                        </v-list-item-title>
                        <v-list-item-subtitle>Plug: {{ item.plug }}</v-list-item-subtitle>
                        <v-list-item-subtitle v-if="item.user">Usuário: {{ item.user.email }}</v-list-item-subtitle>
                        <v-list-item-subtitle v-if="item.charger.point && item.charger.point.name">Local: {{ item.charger.point.name }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <router-link :to="{ path: nowPath(item.charger.name, item.plug) }" tag="v-btn">
                            <v-btn x-small :class="classColor(item)" block>
                                <v-icon left>remove_red_eye</v-icon>
                                Now
                            </v-btn>
                        </router-link>
                        <v-btn x-small color="warning" class="mt-2 secondary--text" block @click="clear(item.id)"> Reset </v-btn>
                        <v-btn x-small color="success" class="mt-2" block v-if="item.paymentValue > 0" text> R${{ item.paymentValue.toFixed(2) }} </v-btn>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </div>
        <ConfirmModal ref="confirm" />
    </v-container>
</template>

<script>
import statusCharger from "@/helpers/StatusCharger";
import ConfirmModal from "@/components/core/ConfirmModal";
import BoardsError from "@/components/shared/BoardsError.vue";

export default {
    mounted() {
        this.timer = setInterval(this.update, 5000);
        this.update();
    },

    components: { ConfirmModal, BoardsError },

    data() {
        return {
            statusCharger,
            timer: null,
            loading: true,
            onlyPay: false,
            onlyOnline: false,
            items: [],
        };
    },

    computed: {
        nowPath() {
            return (name, plug) => `now/${name}/${plug}`;
        },
        listWithFilter() {
            if (this.onlyPay || this.onlyOnline) {
                return this.items.filter((item) => {
                    if (this.onlyPay && this.onlyOnline) {
                        return item.paymentValue > 0 && item.charger.data.online == 1;
                    } else if (this.onlyPay) {
                        return item.paymentValue > 0;
                    } else if (this.onlyOnline) {
                        return item.charger.data.online == 1;
                    }
                });
            } else {
                return this.items;
            }
        },
        itensByPay() {
            return this.items.filter((item) => {
                return item.paymentValue > 0;
            });
        },
        itensOffline() {
            return this.items.filter((item) => {
                return item.charger.data.online == 0;
            });
        },
    },

    methods: {
        update() {
            this.$http.get("api/v2/session", { continuous: !this.loading }).then((result) => {
                if (this.loading == true)
                    this.loading = false;

                if (result) {
                    this.items = result;
                }
            });
        },
        clear(id) {
            this.$refs.confirm
                .open("Tem certeza?", "Só faça limpeza de carregamentos com problema. A limpeza de carregamento ativo irá apagar todo os dados e as informações serão perdidas.")
                .then((confirm) => {
                    if (confirm) {
                        this.$http
                            .patch("api/v2/session", { id: id })
                            .then(() => {
                                this.update();
                            })
                            .catch((error) => {
                                this.$eventHub.$emit("msgError", error.message ? error.message : "Error ao processar requisição.");
                            });
                    }
                });
        },
        classColor(item) {
            if (item.charger.data.online == 1) {
                const color = this.statusCharger.getComponentStyle(item.status);
                return `${color.color} ${color.secondColor}--text`;
            } else {
                return `grey white--text`;
            }
        },
        icon(item) {
            const color = this.statusCharger.getComponentStyle(item.status);
            return color.icon;
        },
    },

    beforeDestroy() {
        clearInterval(this.timer);
    },
};
</script>